/** @format */

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const dummyData = [
  { name: "Bachelor of Science in Computer Science", st: 56, sc: 34, obc: 90 },
  { name: "Bachelor of Mechanical Engineering", st: 133, sc: 50, obc: 90 },
  { name: "Master of Business Administration", st: 57, sc: 90, obc: 90 },
];

export default function CurrentStatsTable() {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <TableContainer component={Paper}>
        <Table aria-label="seats table" sx={{ borderCollapse: "collapse" }}>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={4}
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#6495ED",
                  color: "#fff",
                  border: "1px solid black",
                }}>
                ONLINE COUNSELLING STATUS (2023-2024)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "pink",
                  border: "1px solid black",
                }}>
                Course Name
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "orange",
                  border: "1px solid black",
                }}>
                ST Category
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#00e676",
                  border: "1px solid black",
                }}>
                SC Category
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#ff7043",
                  border: "1px solid black",
                }}>
                OBC Category
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "pink",
                  border: "1px solid black",
                }}></TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "pink",
                  border: "1px solid black",
                }}>
                Available Seats
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "pink",
                  border: "1px solid black",
                }}>
                Available Seats
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "pink",
                  border: "1px solid black",
                }}>
                Available Seats
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dummyData.map((group, groupIndex) => (
              <TableRow key={groupIndex}>
                <TableCell align="center" sx={{ border: "1px solid black" }}>
                  {group.name}
                </TableCell>
                <TableCell align="center" sx={{ border: "1px solid black" }}>
                  {group.st}
                </TableCell>
                <TableCell align="center" sx={{ border: "1px solid black" }}>
                  {group.sc}
                </TableCell>
                <TableCell align="center" sx={{ border: "1px solid black" }}>
                  {group.obc}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
        <Button
          size="small"
          startIcon={<ArrowBack />}
          variant="outlined"
          onClick={() => navigate("/sch/administrator/seat-selection")}>
          Back
        </Button>
      </Box>
    </>
  );
}
