/** @format */

export const PUBLIC_URLS = {
  account: {
    login: "/account/login",
    checkIfLoggedIn: "/account/checkIfLoggedIn",
  },
  school: {
    getSchools: "/school/listPublic",
  },
};
export const PRIVATE_URLS = {
  academicYear: {
    list: "/academicYear/list",
    create: "/academicYear/create",
    update: "/academicYear/update",
    delete: "/academicYear/delete",
    toggleActiveStatus: "/academicYear/toggleActiveStatus",
  },
  account: {
    changePasswordForUser: "/account/changePasswordForUser",
  },
  role: {
    list: "/role/list",
    create: "/role/create",
    update: "/role/update",
    delete: "/role/delete",
    getRoleKeys: "/role/getRoleKeys",
  },
  employee: {
    list: "/employee/list",
    create: "/employee/create",
    update: "/employee/update",
    delete: "/employee/delete",
    getEmpById: "/employee/details",
    updateLibraryMember: "/employee/updateLibraryMember",
    removeLibraryMember: "/employee/removeLibraryMember",
  },
  designation: {
    list: "/designation/list",
    create: "/designation/create",
    update: "/designation/update",
    delete: "/designation/delete",
  },
  department: {
    list: "/department/list",
    create: "/department/create",
    update: "/department/update",
    delete: "/department/delete",
  },

  offerLetter: {
    list: "/offerLetter/list",
    create: "/offerLetter/create",
    update: "/offerLetter/update",
    delete: "/offerLetter/delete",
  },

  experienceLetter: {
    list: "/experienceLetter/list",
    create: "/experienceLetter/create",
    update: "/experienceLetter/update",
    delete: "/experienceLetter/delete",
  },

  relievingLetter: {
    list: "/relievingLetter/list",
    create: "/relievingLetter/create",
    update: "/relievingLetter/update",
    delete: "/relievingLetter/delete",
  },
  school: {
    list: "/school/list",
    create: "/school/create",
    toggleActiveStatus: "/school/toggleActiveStatus",
    update: "/school/update",
    details: "/school/details",
    addFiles: "/school/addFiles",
    removeFile: "/school/removeFile",
  },
  class: {
    list: "/class/list",
    create: "/class/create",
    update: "/class/update",
    delete: "/class/delete",
  },
  section: {
    list: "/section/list",
    create: "/section/create",
    update: "/section/update",
    delete: "/section/delete",
  },
  semester: {
    list: "/semester/list",
    create: "/semester/create",
    update: "/semester/update",
    delete: "/semester/delete",
  },
  subject: {
    list: "/subject/list",
    create: "/subject/create",
    update: "/subject/update",
    delete: "/subject/delete",
    getStudentSubject: "/subject/getStudentSubject",
  },
  fileUpload: {
    list: "/fileUpload/list",
    create: "/fileUpload/create",
    update: "/fileUpload/update",
    delete: "/fileUpload/delete",
  },

  gallery: {
    list: "/gallery/list",
    create: "/gallery/create",
    update: "/gallery/update",
    delete: "/gallery/delete",
    addFiles: "/gallery/addFiles",
    removeFile: "/gallery/removeFile",
    listPublic: "/gallery/listPublic",
  },
  assignment: {
    list: "/assignment/list",
    create: "/assignment/create",
    update: "/assignment/update",
    delete: "/assignment/delete",
  },
  notice: {
    list: "/notice/list",
    create: "/notice/create",
    update: "/notice/update",
    delete: "/notice/delete",
    listPublic: "/notice/listPublic",
  },
  calenderEvent: {
    list: "/calenderEvent/list",
    create: "/calenderEvent/create",
    update: "/calenderEvent/update",
    delete: "/calenderEvent/delete",
    deleteCalenderEvent: "/calenderEvent/deleteCalenderEvent",
  },

  news: {
    list: "/news/list",
    create: "/news/create",
    update: "/news/update",
    delete: "/news/delete",
    listPublic: "/news/listPublic",
  },
  event: {
    list: "/event/list",
    listPublic: "/event/listPublic",
    create: "/event/create",
    update: "/event/update",
    delete: "/event/delete",
  },
  holiday: {
    list: "/holiday/list",
    create: "/holiday/create",
    update: "/holiday/update",
    delete: "/holiday/delete",
  },
  awards: {
    list: "/awards/list",
    create: "/awards/create",
    update: "/awards/update",
    delete: "/awards/delete",
    listPublic: "/awards/listPublic",
  },
  splashNews: {
    list: "/splashNews/list",
    listPublic: "/splashNews/listPublic",
    create: "/splashNews/create",
    update: "/splashNews/update",
    delete: "/splashNews/delete",
    toggle: "/splashNews/toggleEnable",
  },
  student: {
    list: "/student/list",
    create: "/student/create",
    update: "/student/update",
    delete: "/student/delete",
    details: "/student/details",
    overView: "/student/overView",
    promote: "/student/promote",
    resuffle: "/student/resuffle",
    updateTransportMember: "/student/updateTransportMember",
    removeTransportMember: "/student/removeTransportMember",
    updateLibraryMember: "/student/updateLibraryMember",
    removeLibraryMember: "/student/removeLibraryMember",
    updateHostelMember: "/student/updateHostelMember",
    removeHostelMember: "/student/removeHostelMember",
    getDashboardStudentDetails: "/student/getDashboardStudentDetails",
    downloadAllStudentsExcel: "/student/downloadAllStudentsExcel",
    downloadStudentsExcel: "/student/downloadStudentsExcel",
    downloadStudentsPdf: "/student/downloadStudentsPdf",
    updatePhoto: "/student/updatePhoto",
    bulkUpdateSheet: "/student/bulkUpdateSheet",
    bulkUpdate: "/student/bulkUpdate",
    getBulkStudentAdmitSheet: "/student/getBulkStudentAdmitSheet",
    bulkStudentAdmit: "/student/bulkStudentAdmit",
    assignMentor: "/student/assignMentor",
  },
  studentActivity: {
    list: "/studentActivity/list",
    create: "/studentActivity/create",
    update: "/studentActivity/update",
    delete: "/studentActivity/delete",
  },
  course: {
    list: "/course/list",
    create: "/course/create",
    update: "/course/update",
    delete: "/course/delete",
    uploadCourseMaterial: "/course/uploadCourseMaterial",
    deleteMaterial: "/course/deleteCourseMaterial",
  },
  meeting: {
    list: "/meeting/list",
    create: "/meeting/create",
    update: "/meeting/update",
    delete: "/meeting/delete",
    listStudent: "/meeting/listStudent",
    join: "/meeting/join",
  },
  visitorInfo: {
    list: "/visitorInfo/list",
    create: "/visitorInfo/create",
    update: "/visitorInfo/update",
    delete: "/visitorInfo/delete",
    updateCheckout: "/visitorInfo/updateCheckout",
  },

  studentCheckout: {
    list: "/studentCheckout/list",
    update: "/studentCheckout/update",
    delete: "/studentCheckout/delete",
    downloadPdf: "/studentCheckout/downloadPdf",
    donwloadExcel: "/studentCheckout/downloadExcel",
  },
  guardianFeedback: {
    list: "/guardianFeedback/list",
    listPublic: "/guardianFeedback/listPublic",
    create: "/guardianFeedback/create",
    update: "/guardianFeedback/update",
    delete: "/guardianFeedback/delete",
  },
  examGrade: {
    list: "/examGrade/list",
    create: "/examGrade/create",
    update: "/examGrade/update",
    delete: "/examGrade/delete",
  },
  examTerm: {
    list: "/examTerm/list",
    create: "/examTerm/create",
    update: "/examTerm/update",
    delete: "/examTerm/delete",
  },
  examSchedule: {
    list: "/examSchedule/list",
    create: "/examSchedule/create",
    update: "/examSchedule/update",
    delete: "/examSchedule/delete",
    generateHallTicket: "/examSchedule/generateHallTicket",
  },
  examAttendance: {
    details: "/examAttendance/details",
    update: "/examAttendance/update",
  },
  receiptTitle: {
    list: "/receiptTitle/list",
    create: "/receiptTitle/create",
    update: "/receiptTitle/update",
    toggleActiveStatus: "/receiptTitle/toggleActiveStatus",
  },
  courseContent: {
    list: "/courseContent/list",
    create: "/courseContent/create",
    updateChapterDetails: "/courseContent/updateChapterDetails",
    deleteChapter: "/courseContent/deleteChapter",
    // create content
    addContentToChapter: "/courseContent/addContentToChapter",
    updateContent: "/courseContent/updateContent",
    getDetailsTeachers: "courseContent/getDetailsTeachers",
    deleteContent: "/courseContent/deleteContent",
    changeOrderSequence: "/courseContent/changeOrderSequence",
  },

  roomType: {
    list: "/roomType/list",
    create: "/roomType/create",
    update: "/roomType/update",
    delete: "/roomType/delete",
  },
  room: {
    list: "/room/list",
    create: "/room/create",
    update: "/room/update",
    delete: "/room/delete",
    updateBed: "/room/updateBed",
    toggleBedStatus: "/room/toggleBedStatus",
  },
  hostel: {
    list: "/hostel/list",
    create: "/hostel/create",
    update: "/hostel/update",
    delete: "/hostel/delete",
  },
  firm: {
    list: "/firm/list",
    create: "/firm/create",
    update: "/firm/update",
    delete: "/firm/delete",
  },
  vehicle: {
    list: "/vehicle/list",
    create: "/vehicle/create",
    update: "/vehicle/update",
    delete: "/vehicle/delete",
  },
  route: {
    list: "/route/list",
    create: "/route/create",
    update: "/route/update",
    delete: "/route/delete",
    addStop: "/route/addStop",
    removeStop: "/route/removeStop",
    updateStop: "/route/updateStop",
  },

  vehicleLog: {
    list: "/vehicleLog/list",
    create: "/vehicleLog/create",
    update: "/vehicleLog/update",
    delete: "/vehicleLog/delete",
    downloadPdf: "/vehicleLog/downloadPdf",
  },

  maintenanceFuel: {
    list: "/maintenance/listFuel",
    create: "/maintenance/createFuel",
    update: "/maintenance/updateFuel",
    delete: "/maintenance/deleteFuel",
  },

  maintenanceRepair: {
    list: "/maintenance/listRepair",
    create: "/maintenance/createRepair",
    update: "/maintenance/updateRepair",
    delete: "/maintenance/deleteRepair",
  },

  maintenanceTyre: {
    list: "/maintenance/listTyre",
    create: "/maintenance/createTyre",
    update: "/maintenance/updateTyre",
    delete: "/maintenance/deleteTyre",
  },

  maintenanceGreecing: {
    list: "/maintenance/listGreecing",
    create: "/maintenance/createGreecing",
    update: "/maintenance/updateGreecing",
    delete: "/maintenance/deleteGreecing",
  },

  feeMapCategory: {
    list: "/feeMapCategory/list",
    create: "/feeMapCategory/create",
    createMultiple: "/feeMapCategory/createMultiple",
    update: "/feeMapCategory/update",
    delete: "/feeMapCategory/delete",
  },
  feeMap: {
    list: "/feeMap/list",
    create: "/feeMap/create",
    update: "/feeMap/update",
    delete: "/feeMap/delete",
    toggleActiveStatus: "/feeMap/toggleActiveStatus",
  },
  receipt: {
    getStudentsList: "/receipt/getStudentsList",
    getFeeDetails: "/receipt/getFeeDetails",
    collectFees: "/receipt/collectFees",
    previewReceipt: "/receipt/previewReceipt",
    getReceiptsPaidWithCheque: "/receipt/getReceiptsPaidWithCheque",
    updateSingleReceiptReconciliationStatusStatus:
      "/receipt/updateSingleReceiptReconciliationStatus",
    updateMultipleReceiptReconciliationStatus:
      "/receipt/updateMultipleReceiptReconciliationStatus",
    getAmountCollectedWithDifferentModes:
      "/receipt/getAmountCollectedWithDifferentModes",
    getBalanceFeeReport: "/receipt/getBalanceFeeReport",
    downloadBalanceFeeReport: "/receipt/downloadBalanceFeeReport",
    getAllReceipts: "/receipt/getAllReceipts",
    downloadReceipt: "/receipt/downloadReceipt",
    downloadReceiptStaff: "/receipt/downloadReceiptStaff",
    downloadFeeOverView: "/receipt/downloadFeeOverView",
    getAllPastDues: "/receipt/getAllPastDues",
    getMyReceipts: "/receipt/getMyReceipts",
  },
  salaryGrade: {
    list: "/salaryGrade/list",
    create: "/salaryGrade/create",
    update: "/salaryGrade/update",
    delete: "/salaryGrade/delete",
  },
  paymentHistory: {
    downloadPdf: "/paymentHistory/downloadPdf",
    list: "/paymentHistory/list",
    downloadDeductionPdf: "/paymentHistory/downloadDeductionPdf",
  },

  book: {
    list: "/book/list",
    create: "/book/create",
    update: "/book/update",
    delete: "/book/delete",
  },

  bookIssue: {
    list: "/bookIssue/list",
    create: "/bookIssue/create",
    submit: "/bookIssue/submit",
    delete: "/bookIssue/delete",
    downloadPdf: "/bookIssue/downloadPdf",
    downloadExcel: "/bookIssue/downloadExcel",
  },
  periodical: {
    list: "/periodical/list",
    create: "/periodical/create",
    update: "/periodical/update",
    delete: "/periodical/delete",
  },
  leaveType: {
    list: "/leaveType/list",
    create: "/leaveType/create",
    update: "/leaveType/update",
    delete: "/leaveType/delete",
  },
  leaveApplication: {
    list: "/leaveApplication/list",
    create: "/leaveApplication/createApplicationForEmployee",
    listEmployeeApplications: "/leaveApplication/listEmployeeApplications",
    employeeLeaveCredits: "/leaveApplication/employeeLeaveCredits",
    approveLeave: "/leaveApplication/approveLeave",
    rejectLeave: "/leaveApplication/rejectLeave",
    downloadExcel: "/leaveApplication/downloadExcel",
    downloadPdf: "/leaveApplication/downloadPdf",
  },
  studentMarks: {
    listStudentMarks: "/studentMark/listStudentMarks",
    updateStudentsMarks: "/studentMark/updateStudentsMarks",
    getbulkUpdateStudentMarks: "/studentMark/getbulkUpdateStudentMarks",
    bulkUpdateStudentMarks: "/studentMark/bulkUpdateStudentMarks",
    getExamResult: "/studentMark/getExamResult",
    getbulkUpdateAllSectionStudentMarks:
      "/studentMark/getbulkUpdateAllSectionStudentMarks",
    downloadExamResult: "/studentMark/downloadExamResult",
    getGraphDataSubjectWise: "/studentMark/getGraphDataSubjectWise",
    getGraphDataDivisionWise: "/studentMark/getGraphDataDivisionWise",
    donwloadMarksCard: "/studentMark/donwloadMarksCard",
  },
  teacherActivity: {
    list: "/teacherActivity/list",
    create: "/teacherActivity/create",
    update: "/teacherActivity/update",
    delete: "/teacherActivity/delete",
  },
  storage: {
    details: "/storage/getDetails",
  },
  preadmissionEnqiry: {
    list: "/preadmissionEnqiry/list",
    create: "/preadmissionEnqiry/create",
    update: "/preadmissionEnqiry/updateStatus",
    // update: "/preadmissionEnqiry/update",
    delete: "/preadmissionEnqiry/delete",
  },
  preadmissionExam: {
    list: "/preadmissionExam/list",
    create: "/preadmissionExam/create",
    update: "/preadmissionExam/update",
    delete: "/preadmissionExam/delete",
  },
  preadmissionExamSchedule: {
    list: "/preadmissionExamSchedule/list",
    create: "/preadmissionExamSchedule/create",
    update: "/preadmissionExamSchedule/update",
    delete: "/preadmissionExamSchedule/delete",
    enableExamLink: "/preadmissionExamSchedule/enableExamLink",
    getVenueDetails: "/preadmissionExamSchedule/getVenueDetails",
    getExamDetails: "/preadmissionExamSchedule/getExamDetails",
    sendVenueDetailsToStudents:
      "/preadmissionExamSchedule/sendVenueDetailsToStudents",
  },
  employeeAttendance: {
    list: "/employeeAttendance/list",
    update: "/employeeAttendance/update",
    getAttendanceReport: "/employeeAttendance/getAttendanceReport",
    getEmployeeAttendanceSummaryForToday:
      "/employeeAttendance/getEmployeeAttendanceSummaryForToday",
  },
  studentAttendance: {
    list: "/studentAttendance/list",
    update: "/studentAttendance/update",
    getAttendanceReport: "/studentAttendance/getAttendanceReport",
    getAttendanceOverview: "/studentAttendance/getAttendanceOverview",
    donwloadAbsentReport: "/studentAttendance/donwloadAbsentReport",
    getAttendanceSummaryForToday:
      "/studentAttendance/getAttendanceSummaryForToday",
    getMyAttendance: "/studentAttendance/getMyAttendance",
  },
  report: {
    groupedLibraryData: "/report/groupedLibraryData",
    downloadGroupedLibraryData: "/report/downloadGroupedLibraryData",
    getStudentActivityReport: "/report/getStudentActivityReport",
    getStudentActivityReportPdf: "/report/getStudentActivityReportPdf",
    getStudentReport: "/report/getStudentReport",
    getAllEmployeesAttendanceReportForParticularMonth:
      "/report/getAllEmployeesAttendanceReportForParticularMonth",
    getEmployeeAttendanceReport: "/report/getEmployeeAttendanceReport",
    getAllStudentsAttendanceReportForParticularMonth:
      "/report/getAllStudentsAttendanceReportForParticularMonth",

    getStudentAttendanceReport: "/report/getStudentAttendanceReport",

    downloadStudentReport: "/report/downloadStudentReport",
  },
  certificate: {
    getStudyCertificate: "/certificate/getStudyCertificate",
    getBulkStudyCertificate: "/certificate/getBulkStudyCertificate",
    getTransferCertificate: "/certificate/getTransferCertificate",
    getBulkTransferCertificate: "/certificate/getBulkTransferCertificate",
  },
  item: {
    list: "/item/list",
    create: "/item/create",
    update: "/item/update",
    delete: "/item/delete",
    downloadPdf: "/item/downloadPdf",
    downloadExcel: "/item/downloadExcel",
  },
  vendor: {
    list: "/vendor/list",
    create: "/vendor/create",
    update: "/vendor/update",
  },
  issue: {
    list: "/issue/list",
    create: "/issue/create",
    updateStatus: "/issue/updateStatus",
  },
  building: {
    list: "/building/list",
    create: "/building/create",
    update: "/building/update",
    delete: "/building/delete",
  },

  degree: {
    list: "/degree/list",
    create: "/degree/create",
    update: "/degree/update",
    delete: "/degree/delete",
  },
  degreeCode: {
    list: "/degreeCode/list",
    create: "/degreeCode/create",
    update: "/degreeCode/update",
    delete: "/degreeCode/delete",
  },

  buildingRoom: {
    list: "/buildingRoom/list",
    create: "/buildingRoom/create",
    update: "/buildingRoom/update",
    delete: "/buildingRoom/delete",
  },
  subjectType: {
    list: "/subjectType/list",
    create: "/subjectType/create",
    update: "/subjectType/update",
    delete: "/subjectType/delete",
  },

  subjectCategory: {
    list: "/subjectCategory/list",
    create: "/subjectCategory/create",
    update: "/subjectCategory/update",
    delete: "/subjectCategory/delete",
  },
  subjectComponent: {
    list: "/subjectComponent/list",
    create: "/subjectComponent/create",
    update: "/subjectComponent/update",
    delete: "/subjectComponent/delete",
  },
  courseOutcome: {
    list: "/courseOutcome/list",
    create: "/courseOutcome/create",
    update: "/courseOutcome/update",
    delete: "/courseOutcome/delete",
  },
  pso: {
    list: "/pso/list",
    create: "/pso/create",
    update: "/pso/update",
    delete: "/pso/delete",
  },
  programOutcome: {
    list: "/programOutcome/list",
    create: "/programOutcome/create",
    update: "/programOutcome/update",
    delete: "/programOutcome/delete",
  },
  coPoMapping: {
    list: "/coPoMapping/list",
    create: "/coPoMapping/create",
    update: "/coPoMapping/update",
    delete: "/coPoMapping/delete",
  },

  coPsoMapping: {
    list: "/coPsoMapping/list",
    create: "/coPsoMapping/create",
    update: "/coPsoMapping/update",
    delete: "/coPsoMapping/delete",
  },
  task: {
    list: "/task/list",
    create: "/task/create",
    update: "/task/update",
    delete: "/task/delete",
  },
  asset: {
    list: "/asset/list",
    create: "/asset/create",
    update: "/asset/update",
    delete: "/asset/delete",
  },
  slot: {
    list: "/slot/list",
    create: "/slot/create",
    update: "/slot/update",
    delete: "/slot/delete",
  },
  studentTimeTable: {
    list: "/studentTimeTable/list",
    create: "/studentTimeTable/create",
    update: "/studentTimeTable/update",
    delete: "/studentTimeTable/delete",
  },
  // HOD
  employeeSubjectMap: {
    list: "/employeeSubjectMap/list",
    assignSubjects: "/employeeSubjectMap/assignSubjects",
    removeSubject: "/employeeSubjectMap/removeSubject",
    getMyFilters: "/employeeSubjectMap/myFilters",
  },

  examTitle: {
    list: "/examTitle/list",
    create: "/examTitle/create",
    update: "/examTitle/update",
    delete: "/examTitle/delete",
  },

  seatingArrangement: {
    list: "/seatingArrangement/list",
    create: "/seatingArrangement/create",
    update: "/seatingArrangement/update",
    delete: "/seatingArrangement/delete",
  },

  savedQuestion: {
    list: "/savedQuestion/list",
    create: "/savedQuestion/create",
    update: "/savedQuestion/update",
    delete: "/savedQuestion/delete",
  },

  cieExam: {
    list: "/cieExam/list",
    create: "/cieExam/create",
    update: "/cieExam/update",
    delete: "/cieExam/delete",
    getMarksUpdateSheet: "/cieExam/getMarksUpdateSheet",
    uploadMarks: "/cieExam/uploadMarks",
    getSingleMarksUpdateSheet: "/cieExam/getSingleMarksUpdateSheet",
    uploadMarksSingle: "/cieExam/uploadMarksSingle",
    getCOAttainment: "/cieExam/getCOAttainment",
    downloadStudentMarks: "/cieExam/downloadStudentMarks",
    getPOAttainment: "/cieExam/getPOAttainment",
    getPSOAttainment: "/cieExam/getPSOAttainment",
    getCOAttainmentCourseLevel: "/cieExam/getCOAttainmentCourseLevel",
  },

  seat: {
    list: "/seat/list",
    create: "/seat/create",
    delete: "/seat/delete",
    deleteAll: "/seat/deleteAll",
  },

  offeredSubject: {
    list: "/offeredSubject/list",
    create: "/offeredSubject/create",
    publishOfferedSubject: "/offeredSubject/publishOfferedSubject",
    delete: "/offeredSubject/delete",
    getOfferedSubjects: "/offeredSubject/getOfferedSubjects",
    registerSubject: "/offeredSubject/registerSubject",
  },

  semesterSubjectMapping: {
    list: "/semesterSubjectMapping/list",
    create: "/semesterSubjectMapping/create",
    update: "/semesterSubjectMapping/update",
    delete: "/semesterSubjectMapping/delete",
    processSubjectAllocation:
      "/semesterSubjectMapping/processSubjectAllocation",
  },
};
