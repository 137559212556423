/** @format */

import React, { useState, useEffect } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";

export default function FormInput({
  name,
  multiline = false,
  rows = 4,
  label = "default label",
  formik,
  required = false,
  disabled = false,
  containerStyle = {},
  ...rest
}) {
  const [recognition, setRecognition] = useState(null);
  const [isRecognizing, setIsRecognizing] = useState(false);

  // Initialize Speech Recognition
  const initRecognition = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.lang = "en-US";
      recognitionInstance.interimResults = false;
      recognitionInstance.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        formik.setFieldValue(name, transcript);
      };
      recognitionInstance.onstart = () => setIsRecognizing(true); // Update recognition status
      recognitionInstance.onend = () => setIsRecognizing(false); // Update recognition status
      setRecognition(recognitionInstance);
    } else {
      alert("Speech Recognition is not supported in this browser.");
    }
  };

  // Start speech recognition
  const startRecognition = () => {
    if (recognition && !isRecognizing) {
      recognition.start();
    }
  };

  // Initialize recognition on component mount
  useEffect(() => {
    initRecognition();
    // Clean up recognition instance on unmount
    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, []);

  const handleInputChange = (e) => {
    const { value } = e.target;
    formik.setFieldValue(name, value);
  };

  return (
    <TextField
      required={required}
      id={name}
      name={name}
      label={label}
      multiline={multiline}
      rows={multiline ? rows : 1}
      disabled={disabled}
      placeholder={`Enter ${label}`}
      fullWidth
      value={formik.values[name] || ""}
      onChange={handleInputChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      sx={{
        mt: 2,
        borderWidth: 1,
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={startRecognition}
              aria-label="speech-to-text">
              <MicIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
        style: {
          borderWidth: 1,
          height: multiline ? "auto" : "42px",
          borderRadius: (theme) => theme.shape.borderRadius,
        },
      }}
      {...rest}
    />
  );
}
